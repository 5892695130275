.soft{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.patches {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
}

@media screen and (max-width: 1600px) {
  .patches {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 1400px) {
  .patches {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 980px) {
  .patches {
    grid-template-columns: 1fr;
  }
}
