.notFound{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
}
.notFound__text{
    font-size: 50px;
    font-weight: 300;
}
.notFound__link{
    font-size: 30px;
}