* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: lightgray;
}

#root {
  height: 100%;
  width: 100%;
}

ul {
  list-style-type: none;
  list-style-position: inside;
  padding-left: 0;
}

.App {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.header {
  background-color: red;
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
}

.main {
  margin-top: 50px;
  display: flex;
}
.projectInfo {
  width: 100%;
  max-width: 1400px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.buttons {
  margin-left: auto;
  margin-right: auto;
}
.projectMenu {
  margin-top: 100px;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 200px;
  height: 200px;
  border-radius: 37px;
  background: #e0e0e0;
  box-shadow: 29px 29px 26px #a4a4a4, -29px -29px 26px #ffffff;
}

.agent_tabs {
  display: flex;
  column-gap: 5px;
  margin-bottom: 10px;
}

.agent_tab {
  border: 1px solid #dae0e7;
  background: rgba(238, 231, 231, 0.51);
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  padding: 10px;
  color: #000000;
}

.agent_tab.active {
  background: #f9f9f9;
  color: gray;
}

.agent_tab:hover {
  background: rgb(255, 251, 251);
  color: gray;
}

.ant-pagination-item {
  background-color: darkgray !important;
  border-color: transparent !important;
  color: black !important;
}
.ant-menu-item {
  margin: 0 !important;
}

.ant-menu-item:hover {
  border-radius: 0 !important;
}

.ant-menu-item-selected {
  border-radius: 0 !important;
}

.empty-enter {
  opacity: 0;
  transform: scale(0.9);
}
.empty-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.empty-exit {
  opacity: 1;
}
.empty-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.unitsPage .ant-col {
  height: 100% !important;
}

.dividerBox {
  margin-top: auto;
}

.units__box{
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-bottom: 20px;
}
