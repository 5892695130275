.infoItem {
  display: flex;
  column-gap: 10px;
  align-items: center;
  justify-content: flex-start;
}

.title{

}
