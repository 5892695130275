.customSelect{
    display: flex;
    column-gap: 0px;
    align-items: center;
    max-width: 258px;
    width: 100%;
}

.input{
    width: 100%;
}