.unitSentTestForm {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding: 10px;
  background-color: #ffffff;
  position: relative;
  max-width: 800px;
  width: 100%;
}

.form {
  margin-top: 30px;
}


