.card {
  cursor: auto;
  background-color: darkgray;
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 1920px) {
  .card {
    max-width: 200px;
  }
}

.cardText {
  max-width: 180px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardContent{
  box-shadow: none!important;
  cursor: auto;
}

.cardContent:hover{
  box-shadow: none!important;
}