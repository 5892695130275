.units {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.box {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-bottom: 20px;
}

.listBox {
  display: grid;
  grid-template-columns: repeat(6, 0.3fr);
  gap: 10px;
}

.buttonsBox {
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;
  margin-top: 5px;
}

.editForm {
  padding: 50px 20px 20px 20px;
  position: relative;
  background-color: #ffffff;
  max-width: 800px;
  width: 100%;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
}

.cardInfo {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.description_text span:nth-child(2) {
  width: 100px;
  overflow: hidden;
  display: inline-block!important;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.logo {
  object-fit: contain;
  height: 180px;
  width: 100%;
}

/*.ant-list-item .unit_card_arrow::before{*/
/*  content: "1212";*/
/*}*/
.arrowImg{
  position: absolute;
  width: 40px;
  height: 10px;
  right: -37px;
  top: 50%;
  transform: translateY(-50%);
}

.unitsList__leftArrow{
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
}

.unitsList__rightArrow{
  position: absolute;
  z-index: 100;
  transform: translateY(-50%);
  right: 10px;
  top: 50%;
}
