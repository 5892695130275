.tableDrawerContent {
}

.wrapper {
  display: flex;
  column-gap: 20px;
}

.tabs {
  display: flex;
  column-gap: 20px;
}

.content {
  width: 100%;
}
