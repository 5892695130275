.products{
    display: flex;
    flex-direction: column;
    height: 100%;
}

.description_text span:nth-child(2) {
    width: 100px;
    overflow: hidden;
    display: inline-block!important;
    text-overflow: ellipsis;
    white-space: nowrap;
}