.login{
    background-color: lightgray;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.wrapper{
    background-color: #FFFFFF;
    padding: 20px 10px;
    border-radius: 10px;
    max-width: 350px;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    align-items: center;
    position: relative;
}

.errorText{
    display: block;
    color: red;
    margin-bottom: 10px;
}

.img{
    width: 50px;
    height: 50px;
    align-self: center;
}

.madeInRussia{
    background-color: #000000;
}

.version{
    position: absolute;
    top: 5px;
    right: 5px;
}